import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { API_URL } from "src/environments/settings";
import { Report } from "./state/reports.model";

interface Filters {
  searchQ?: string;
  isActive?: boolean;
}

@Injectable({
  providedIn: "root"
})
export class ReportsService {
  
  private apiUrl: string; 
  
  constructor(
    private http: HttpClient,
    private env: EnvironmentService
    ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  };

  list(skipCount: number, maxResult: number, filters?: Filters) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        ...filters,
        MaxResultCount: maxResult,
        SkipCount: skipCount
      }
    });
    return this.http.get<any>(`${this.apiUrl}/app/powerBi`, { params });
  }

  create(report: Report) {
    return this.http.post<any>(`${this.apiUrl}/app/powerBi`, report);
  }

  update(report: Report) {
    return this.http.put<any>(`${this.apiUrl}/app/powerBi/${report.id}`, report);
  }

  remove(reportId: string) {
    return this.http.delete(`${this.apiUrl}/app/powerBi/${reportId}`);
  }
}
 