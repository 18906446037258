import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { AdminPagesStateModel } from "../../admin.state";

export class ReportsStateModel extends AdminPagesStateModel {
  items: Report[];
  itemsAsOptions: SelectOption[];
}

export interface Report {
  id?: string;
  reportId: string;
  name: string;
  comments?: string;
  isActive: boolean;
  description?: string;
};