import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { getUpdatedItems } from "src/app/shared/utils/utils";
import { AdminPagesStateDefaults, SetError } from "../../admin.state";
import { ReportsService } from "../reports.service";
import { AddReport, LoadReports, RemoveReport, UpdateReport } from "./reports.actions";
import { ReportsStateModel } from "./reports.model";

@State<ReportsStateModel>({
  name: "reports",
  defaults: {
    ...AdminPagesStateDefaults,
    itemsAsOptions: []
  }
})
@Injectable()
export class ReportsState {
  
  constructor(
    private reportsService: ReportsService
  ) {}

  isFiltered: boolean;

  @Selector() static reports(state: ReportsStateModel) { return state.items; }
  @Selector() static reportsAsOptions(state: ReportsStateModel) { return state.itemsAsOptions; }
  @Selector() static loading(state: ReportsStateModel) { return state.loading; }
  @Selector() static saving(state: ReportsStateModel) { return state.saving; }

  @Action(LoadReports)
  loadReports(ctx: StateContext<ReportsStateModel>, action: LoadReports) {
    ctx.patchState({
      items: [],
      loading: true
    });

    return this.reportsService.list(0, 1000, action.filters?.items || {}).pipe(
      tap(
        (response) => {
          this.isFiltered = action.filters?.hasEnabledFilters;

          ctx.patchState({
            items: response.items,
            itemsAsOptions: response.items.map((r) => {
              return {
                text: r.name,
                value: r.id
              }
            }),
            loaded: true,
            loading: false
          });
        },
        (err) => {
          ctx.patchState({
            loading: false,
            items: []
          });
          ctx.dispatch(new SetError({ loading: err }));
        }
      )
    );
  }

  @Action(AddReport)
  addReport(ctx: StateContext<ReportsStateModel>, action: AddReport) {
    ctx.patchState({ saving: true });
    
    return this.reportsService.create(action.payload).subscribe(
      (response) => {
        action.callbackSuccess();
        if (this.isFiltered) {
          ctx.dispatch(new LoadReports()).subscribe((res: any) => {
            let reports = [...res.reports.items];
            reports.pop();
            
            ctx.patchState({
              items: [response, ...reports],
              saving: false
            });
          });
        } else {
          const state = ctx.getState();
          ctx.patchState({ items: [response, ...state.items], saving: false });
        }
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(UpdateReport)
  updateReport(ctx: StateContext<ReportsStateModel>, action: UpdateReport){
    ctx.patchState({ saving: true });
    
    return this.reportsService.update(action.payload).subscribe(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: getUpdatedItems(response, state.items),
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false});
      }
    )
  }
  
  @Action(RemoveReport)
  removeReport(ctx: StateContext<ReportsStateModel>, action: RemoveReport) {
    
    return this.reportsService.remove(action.reportId).subscribe(
      () => {
        action.callbackSuccess();
        const state = ctx.getState();
        const updatedReports = state.items.filter(item => {
          return item.id !== action.reportId;
        });
        ctx.patchState({ items: updatedReports});
      },
      (error) => {
        action.callbackError();
      }
    )
  }
}
